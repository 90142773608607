import { useStyletron } from '@parkholidays/legacy_components'
import IconOwnersArea from '@parkholidays/legacy_components/dist/icon/IconOwnersArea'
// import Image from '@parkholidays/legacy_components/dist/image/Image'
import React, { useContext } from 'react'

import VisibleOn, { HiddenOn } from '../../components/hoc/visible-on'
import Anchor from '../../components/ui/anchor'
import Container from '../../components/ui/container'
import Spacer from '../../components/ui/spacer'
import Typography from '../../components/ui/typography'

import Theme from '../../context/theme'
import Seperator from './footer.styled'
import styles from './styles'

import {OWNERS_LOGO_SMALL } from 'src/constants'
import {PH_PRIVACY_POLICY, PL_PRIVACY_POLICY} from 'src/config'

const PL_LTD = "Park Leisure 2000 Ltd" 
const PH_LTD = "Park Holidays UK Ltd"

/**
 * Application Footer Component
 *
 * @returns
 */
const AppFooter = () => {
  const [css, theme] = useStyletron()
  const {
    footerStyles,
    containerStyles,
    logoWrapperStyles,
    textWrapperStyles,
    companyTextStyles,
    privacyPolicyLinkStyles,
  } = styles({ theme })

  const { theme: themeContext } = useContext(Theme);

  console.log(privacyPolicyLinkStyles);

  footerStyles.backgroundColor = themeContext.palette.corporate;

  const privacyHref = themeContext.isPl2k ? PL_PRIVACY_POLICY : PH_PRIVACY_POLICY;

  return (
    <footer role="contentinfo" className={css(footerStyles)}>
      <Container $style={containerStyles}>
        <div className={css(logoWrapperStyles)}>
        { themeContext.isPl2k ?
          <img src={OWNERS_LOGO_SMALL} alt="Owners Lounge" height="36px"/>
        :
          <IconOwnersArea height="36" />
        }
        </div>

        <div className={css(textWrapperStyles)}>
          <Typography $style={companyTextStyles}>
            &copy; {new Date().getFullYear()} { themeContext.isPl2k ? PL_LTD : PH_LTD}
          </Typography>

          <HiddenOn component={<Seperator />} breakpoints={['xs', 'sm']} />
          <VisibleOn component={<br />} breakpoints={['xs', 'sm']} />

          <Anchor
            aria-label='Privacy Policy'
            href={ privacyHref }
            color='white'
            className={css(privacyPolicyLinkStyles)}
          >
            Privacy Policy
          </Anchor>
        </div>

        <Spacer />

        {/* <Image
          src="https://assets.parkholidays.com/static_assets/affiliation-logos/bhhpa-logo.png"
          alt="British Holiday & Home Parks Association Member"
          width="36px"
          height="36px"
          noLoading
        /> */}
      </Container>
    </footer>
  )
}

export default AppFooter
